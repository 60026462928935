import React from "react"
import tw, { styled } from "twin.macro"
import { SectionTitle } from "./section-title"
import { Packages } from "./packages"
import MoneyIcon from "../images/money-icon.svg"

const PricingSection = styled.div`
  ${tw`
  md:mt-20
  m-auto
  w-full
  max-w-screen-xl
  z-50
  md:-mb-64`}

  @media(min-width: 1450px) {
    margin-bottom: -30rem;
  }
`

const Row = tw.div`
  px-page-gutter  
  flex
  flex-col
  flex-wrap
`

export const Pricing = () => {
  return (
    <PricingSection>
      <Row>
        <div className="md:mb-6 text-center">
          <SectionTitle
            text="Simple Pricing Plans"
            icon={MoneyIcon}
            alt="Money Icon"
          />
        </div>
        <Packages />
      </Row>
    </PricingSection>
  )
}
