import React from "react"
import { DiagonalBox } from "../components/page-elements"
import SEO from "../components/seo"
import { Hero } from "../components/hero"
import { WhatWeDo } from "../components/what-we-do"
import { TheDeal } from "../components/the-deal"
import { OurWork } from "../components/our-work"
import { WhoWeAre } from "../components/who-we-are"
import { Pricing } from "../components/pricing"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <DiagonalBox top="-20%" />
    <Hero />
    <WhatWeDo />
    <TheDeal />
    <OurWork />
    <WhoWeAre />
    <Pricing />
  </>
)

export default IndexPage
