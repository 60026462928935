import React from "react"
import tw from "twin.macro"
import Briefcase from "../images/briefcase.svg"
import { SectionTitle } from "../components/section-title"
import { Portfolio } from "../components/portfolio"

const Container = tw.div`
  px-page-gutter  
  flex
  flex-col
  max-w-screen-xl
  m-auto
`
export const OurWork = () => {
  return (
    <Container id="our-work">
      <SectionTitle
        text="Our Work"
        icon={Briefcase}
        className="my-8 md:mt-8 md:mb-10"
        alt={"Briefcase"}
      />
      <Portfolio />
    </Container>
  )
}
