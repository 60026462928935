import React from "react"
import { H2 } from "./page-elements"
import tw, { styled } from "twin.macro"

const Title = styled(H2)`
  ${tw`uppercase text-center text-header`}
`

const TitleEm = tw.span`
  text-primary
`

const Icon = tw.img`
  mx-2
`

interface SectionTitleProps {
  text: string
  icon?: string
  className?: string
  alt?: string
}

export const SectionTitle: React.FC<SectionTitleProps> = ({
  text,
  icon,
  className,
  alt,
}) => {
  const [first, ...rest] = text.split(" ")

  return (
    <div className={className}>
      <Title>
        <TitleEm>{first}</TitleEm> {rest.join(" ")}
      </Title>

      {icon && (
        <div className="flex justify-center">
          <span className="border-b-2 mb-2 w-16"></span>
          <Icon src={icon} alt={alt} />
          <span className="border-b-2 mb-2 w-16"></span>
        </div>
      )}
    </div>
  )
}
