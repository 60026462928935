import React from "react"
import tw, { styled } from "twin.macro"
import { H2, P, LightDiagonalBox } from "./page-elements"
import { FeatureItem } from "./feature-item"
import Image from "./image"

const MakeItRain = "make-it-rain.png"

const Container = tw.div`
  relative
  md:mt-10
  md:mb-24
`

const Img = styled(Image)`
  ${tw`
`}
`

const Row = tw.div`
  px-page-gutter  
  flex
  flex-row
  flex-wrap
  m-auto
  justify-between
  max-w-screen-xl
`

export const TheDeal = () => {
  return (
    <Container>
      <LightDiagonalBox top="0" />
      <Row>
        <div className="mb-16 mt-8 w-full md:w-1/2 md:p-8">
          <Img src={MakeItRain} alt="A man making it rain." />
        </div>
        <div className="md:w-1/2">
          <H2 className="text-header">
            <span className="text-primary">$0</span> Down,{" "}
            <span className="text-primary">$150</span> Per Month
          </H2>
          <P className="text-body">
            No contract, which means if you’re not completely satisfied you can
            cancel at any time with no fees or hassle. Want more than a standard
            five page site? We have custom pricing based on your needs and any
            additional pages.
          </P>
          <P className="text-body">
            You own your domain, content, listing, and profiles. Cancel anytime
            with no fees or hassle.
          </P>
          <div className="md:flex">
            <div className="md:mr-1">
              <FeatureItem
                title="Hosting Fees Included"
                body="Your monthly payment of $150 includes hosting fees."
              />
              <FeatureItem
                title="Unlimited Edits"
                body="Change your site however you’d like and we’ll publish it the same day."
              />
              <FeatureItem
                title="24/7 Customer Service"
                body="No automated responses, speak to a real person anytime."
              />
            </div>
            <div className="md:ml-1">
              <FeatureItem
                title="Web Design & Development"
                body="Your initial setup includes over 40 hours of design, development, and testing. "
              />
              <FeatureItem
                title="Google Analytics"
                body="Analytics are installed free of charge so you can monitor traffic to your site."
              />
              <FeatureItem
                title="Citations And Listings"
                body="We’ll make sure your website is linked from other sites, like directories, for search engine optimization."
              />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  )
}
