import React from "react"
import tw, { styled } from "twin.macro"
import Image from "../components/image"
import {
  P,
  H3,
  H4,
  TextLink,
  LightDiagonalBox,
} from "../components/page-elements"
import { SectionTitle } from "./section-title"
import Profile from "../images/profile.svg"

const Container = tw.div`
  flex
  relative
  md:mt-20
  m-auto
  w-full
  justify-center
`

const AvatarInfo = tw.div`
  block
  w-2/3
  my-10
  mx-auto
`

const Row = tw.div`
  px-page-gutter  
  flex
  flex-col
`

const StyledImg = styled(Image)`
  ${tw`
    xl:w-1/4
    sm:w-1/2
    rounded-full 
    border-4
    border-primary
    m-auto  
    `}
`

export const WhoWeAre = () => {
  return (
    <Container>
      <LightDiagonalBox top="-10%" />
      <Row>
        <div className="md:mb-6 text-center">
          <SectionTitle text="Who We Are" icon={Profile} alt={"Avatar icon"} />
          <P className="mt-6 text-body text-center m-auto md:w-1/2 ">
            My name is Matthew Moran, I am a husband, proud dog dad, and a web
            developer. I started this business to help provide for my family as
            a way to bring in additional income so we could have a little more
            freedom. I spent years teaching myself how to code and working for
            agencies specializing in marketing and digital advertising. After 5
            years of professional experience, I still love it greatly!
          </P>
          <TextLink to="/about">Learn More</TextLink>
        </div>
        <AvatarInfo>
          <StyledImg src="avatar.png" alt="Headshot of Matthew Moran" />
          <H3 className="text-header text-center">Matthew Moran</H3>
          <H4>Owner/Developer</H4>
        </AvatarInfo>
      </Row>
    </Container>
  )
}
