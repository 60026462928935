import React from "react"
import tw from "twin.macro"
import { HighlightBlocks } from "../components/highlight-blocks"
import { SectionTitle } from "./section-title"
import { P, TextLink } from "./page-elements"
import MobileFirstDesign from "../images/mobile-first-design.svg"
import FullyResponsive from "../images/fully-responsive.svg"
import Optimization from "../images/optimization.svg"
import ComputerIcon from "../images/computer-icon.svg"

const Container = tw.div`
  px-page-gutter  
  max-w-screen-xl
  self-center
  mx-auto
  md:my-24
  md:px-0
  flex
  flex-col
  justify-center
`

const highlights = [
  {
    title: "Mobile First Design",
    body:
      "More than half of internet browsing happens on our cell phones, which is why we design your site for mobile devices first and foremost.",
    icon: MobileFirstDesign,
    alt: "Hand holding a mobile phone",
  },

  {
    title: "Fully Responsive",
    body:
      "We make sure your website is accessible to everyone no matter tablet, desktop, or mobile device they use.",
    icon: FullyResponsive,
    alt: "Mobile Phone and Tablet",
  },
  {
    title: "Optimization",
    body:
      "We’re experts in search engine optimization and will improve your site’s performance for search engines and rankings.",
    icon: Optimization,
    alt: "Chemistry flask and female scientist",
  },
]

export const WhatWeDo = () => {
  return (
    <Container>
      <div className="md:mb-8 text-center">
        <SectionTitle
          text="Our Mission"
          icon={ComputerIcon}
          alt="Computer Icon"
        />
        <P className="mt-6 text-body text-center m-auto md:w-1/2 ">
          We hand-code custom websites for small businesses, ensuring superior
          site performance, optimization, and customer accessibility. This turns
          into more revenue for you and increases the bottom line of your
          business.
        </P>
        <TextLink to="/services">Learn More</TextLink>
      </div>
      <HighlightBlocks highlights={highlights} />
    </Container>
  )
}
