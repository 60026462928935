import React from "react"
import tw from "twin.macro"
import Image from "./image"
import { P, Button } from "../components/page-elements"
import { Card } from "./page-elements/card"

const portfolioItems = [
  {
    image: "sprechers.png",
    title: "Sprecher's Pub",
    description:
      "Sprecher's is a Wisconsin based pub and restaurant committed to using the freshest, locally-sourced ingredients. This is one of the first websites I ever built while working for Local Logic Media.",
    location: "Madison, WI",
    url: "https://sprecherspub.com",
    buttonText: "Visit Sprecher's Pub",
    alt: "Desktop, Tablet, and Mobile examples of sprecherspub.com",
  },
  {
    image: "jillthehuman.png",
    title: "Jill The Human",
    description:
      "Jill The Human is an international known Enneagram guide. She uses the Enneagram as tool to help guide others into a deeper understand of themselves and how they relate to the world.",
    location: "Chicago, IL",
    url: "https://jillthehuman.com",
    buttonText: "Visit Jill The Human",
    alt: "Desktop, Tablet, and Mobile examples of jillthehuman.com",
  },
  {
    image: "above-and-beyond.png",
    title: "Above And Beyond Tuxedos",
    description:
      "Above and Beyond Tuxedos is a family owned business existing to serve wedding, prom, and special event needs. From tuxedos to disc jockeys, they do it all.",
    location: "Deckerville, MI",
    url: "https://aboveandbeyondtuxedo.com",
    buttonText: "Visit Above And Beyond",
    alt: "Desktop, Tablet, and Mobile examples of aboveandbeyondtuxedo.com",
  },
]

const Container = tw.div`
  flex
  flex-wrap
`

export const Portfolio = () => {
  return (
    <Container>
      {portfolioItems.map(
        ({ image, title, description, location, url, buttonText, alt }) => {
          return (
            <Card key={title} className="lg:flex-1 md:mx-6 px-4">
              <Image src={image} className="mt-6" alt={alt} />
              <h3 className="text-header font-black text-lg mt-6">{title}</h3>
              <p className="text-sub mb-6">{location}</p>
              <P className="text-body my-6">{description}</P>
              <Button to={url} target="_blank" rel="noreferrer">
                {buttonText}
              </Button>
            </Card>
          )
        }
      )}
    </Container>
  )
}
