import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { H1, H2, H3, P } from "./page-elements"
import Image from "./image"

const ResponsiveDevices = "mock-up.png"

const Container = tw.div`
    flex
    flex-wrap
    px-page-gutter
    justify-between
    md:mt-10
    w-full
    max-w-screen-xl
    self-center
    m-auto
`

const Button = styled(Link)`
  ${tw`
    bg-base-color
    self-start
    block
    rounded-md 
    mt-6
    px-4 
    md:px-5 
    xl:px-4 
    py-3 
    md:py-4 
    xl:py-3 
    hover:bg-secondary
    md:text-lg 
    text-header 
    font-semibold 
    leading-tight 
    shadow-md
    uppercase
  `}
`

const HeroContent = tw.div`
    md:w-1/2
    flex
    flex-col
    justify-start
`

const HeroCta = tw.div`
    mb-10 
    w-full
    md:w-1/2
    flex
    justify-end
 `

const HeroImage = styled(Image)`
  ${tw`
  w-2/3
  md:w-full
  md:mt-8
`}
`

const SmallP = styled(P)`
  ${tw`
    text-base-color 
    mt-6 
  `}
`

export const Hero = () => {
  return (
    <Container>
      <HeroContent>
        <H1 className="text-base-color">Small Business Websites</H1>
        <H3 className="text-base-color">Web Design & Development </H3>
        <SmallP className="">
          Fully custom websites that drive results starting at $150/mo. We build
          your website so you don't have to, keeping{" "}
          <b>your website out of mind</b>!
        </SmallP>
        <Button to="/contact" role="button">
          Contact us!
        </Button>
      </HeroContent>
      <HeroCta>
        <HeroImage
          src={ResponsiveDevices}
          alt="Example of Sprecher's Pub website on Desktop, tablet, and mobile phone."
        />
      </HeroCta>
    </Container>
  )
}
