import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

interface ImageProps {
  src: string
  alt: string
  className?: string
}

const Image: React.FC<ImageProps> = ({ src, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          relativePath
          publicURL
          extension
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.nodes.find((node: any) => src === node.relativePath),
    [data, src]
  )
  if (!match) {
    return null
  }
  if (match.extension === "svg") {
    return <img src={match.publicURL} {...rest} />
  }
  return <Img fluid={match.childImageSharp.fluid} {...rest} />
}

export default Image
